// saas configuration
export let SAAS_APPLICATION = 'NO';

export const saas_key = '$2a$12$pkLmD5jZdxd6bSNcTg9YT.g2mXz5gS8JTArdIg68c8RK/d7w2y2Gu';
export const saas_apiUrl = 'https://test.thetestserver.xyz';
export const saas_apiParams = `saas_key=${saas_key}&domain=prince.thetestserver.xyz`;// replace with base url (prince.thetestserver.xyz)
export const saas_form_data = {
  'saas_key': saas_key,
  'domain': 'prince.thetestserver.xyz' // base url
};

// for localhost development
// export let BASE_URL =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   "/khadyo_updated_production_saas/laravel/public";

// export let BASE_URL = 'test.thetestserver.xyz';


//for localhost production
// export let BASE_URL =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   `${
//     window.location.port !== ""
//       ? `:${window.location.port}/khadyo/laravel`
//       : "/khadyo/laravel"
//   }`;

//for khadyo.com
// export let BASE_URL =
//   window.location.protocol + "//" + window.location.hostname + "/laravel";

// for production
export let BASE_URL =
  window.location.protocol +
  "//" +
  window.location.hostname +
  `${window.location.port !== ""
    ? `:${window.location.port}`
    : `${!window.location.href.includes(".test") ? "/public" : ""}`
  }`;

